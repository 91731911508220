import Link, { LinkProps } from "next/link";
import {
	ComponentProps,
	ForwardedRef,
	forwardRef,
	PropsWithChildren,
} from "react";
import cx from "classnames";

import { IconName } from "@/components/Icon/Icon";

import s from "./Button.module.scss";
import ButtonContent from "./_ButtonContent";

export interface LinkButtonProps
	extends PropsWithChildren<LinkProps>,
		Omit<ComponentProps<"a">, "href"> {
	rank: "primary" | "secondary" | "tertiary";
	icon?: IconName;
	reverse?: boolean;
	className?: string;
}

export const LinkButton = forwardRef(function LinkButton(
	{
		href,
		className,
		rank = "primary",
		icon,
		children,
		reverse = false,
		...rest
	}: LinkButtonProps,
	ref: ForwardedRef<HTMLAnchorElement>
) {
	return (
		<Link passHref href={href}>
			<a
				{...rest}
				ref={ref}
				className={cx(
					s[rank],
					{
						[s.isReversed]: reverse,
						[s.hasIcon]: !!icon,
					},
					className
				)}
			>
				<ButtonContent icon={icon} rank={rank}>
					{children}
				</ButtonContent>
			</a>
		</Link>
	);
});
