import { ComponentProps, ForwardedRef, forwardRef } from "react";

export type IconName =
	| "arrow-left"
	| "arrow-right"
	| "check"
	| "check-circle"
	| "close"
	| "download-alt"
	| "line"
	| "link"
	| "mail"
	| "menu"
	| "minus"
	| "paypal"
	| "pinterest"
	| "play"
	| "plus"
	| "plus-small"
	| "triangle-down"
	| "triangle-right"
	| "whatsapp"
	| "facebook"
	| "instagram";

interface Props extends ComponentProps<"svg"> {
	icon: IconName;
	size?: number;
}

export const Icon = forwardRef(function Icon(
	{ icon, size = 24, ...props }: Props,
	ref: ForwardedRef<SVGSVGElement>
) {
	return (
		<svg ref={ref} height={size} width={size} {...props}>
			<use href={`/sprite.svg#${icon}`} />
		</svg>
	);
});
