import { ComponentProps, createElement } from "react";
import cx from "classnames";

import style from "./Blockquote.module.scss";

type BlockQuoteProps = ComponentProps<"blockquote">;

type Props = BlockQuoteProps;

export function Blockquote({ children, className, ...rest }: Props) {
	const props = {
		...rest,
		className: cx(className, style.blockquote),
	};

	return createElement("blockquote", props, children);
}
