import {
	createElement,
	HTMLAttributes,
	PropsWithRef,
	forwardRef,
	ForwardedRef,
} from "react";
import cx from "classnames";

import style from "./Heading.module.scss";

type Props = PropsWithRef<{
	as: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p";
	sizeDesktop: "xxl" | "xl" | "lg" | "md" | "sm" | "xs" | "xxs" | "price";
	sizeMobile: "xxl" | "xl" | "lg" | "md" | "sm" | "xs" | "xxs" | "price";
	ref?: ForwardedRef<HTMLHeadingElement>;
}> &
	(HTMLAttributes<HTMLHeadingElement> | HTMLAttributes<HTMLParagraphElement>);

export const Heading = forwardRef(function Heading(
	{ as, children, className, sizeDesktop, sizeMobile, ...rest }: Props,
	ref: ForwardedRef<HTMLHeadingElement>
) {
	const mobile = `${sizeMobile}Mobile`;
	const desktop = `${sizeDesktop}Desktop`;
	if (!as) {
		throw Error("Heading component requires an `as` prop.");
	}

	const props = {
		...rest,
		ref,
		className: cx(style.heading, style[desktop], style[mobile], className),
	};

	return createElement(as, props, children);
});
