import { Icon, IconName } from "../Icon/Icon";
import { Text } from "../Typography";
export interface ButtonContentProps {
	rank: "primary" | "secondary" | "tertiary";
	icon?: IconName;
	text?: string;
	children?: React.ReactNode;
}

export default function ButtonContent({ icon, children }: ButtonContentProps) {
	return (
		<>
			{children && <Text as="span">{children}</Text>}
			{icon && <Icon icon={icon!} />}
		</>
	);
}
