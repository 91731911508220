import {
	ComponentProps,
	createElement,
	ForwardedRef,
	forwardRef,
	PropsWithRef,
} from "react";
import cx from "classnames";
import { Icon, IconName } from "@/components/Icon/Icon";

import s from "./IconButton.module.scss";

type GenericProps = PropsWithRef<{
	icon: IconName;
	rank?: "primary" | "secondary";
	label: string;
	showLabel?: boolean;
	reverse?: boolean;
	size?: "normal" | "small";
}>;

interface ButtonProps extends GenericProps, ComponentProps<"button"> {
	as?: "button";
}

interface LinkProps extends GenericProps, ComponentProps<"a"> {
	as: "a";
}
export type IconButtonProps = ButtonProps | LinkProps;

const iconSizes = {
	small: 16,
	normal: 24,
};

export const IconButton = forwardRef(function IconButton(
	{
		as = "button",
		rank = "primary",
		className,
		icon,
		label,
		showLabel = false,
		reverse = false,
		size = "normal",
		...rest
	}: IconButtonProps,
	ref: ForwardedRef<HTMLButtonElement | HTMLAnchorElement>
) {
	let props = {
		...rest,
		ref,
		className: cx(
			s.button,
			s[rank],
			s[reverse ? "isReversed" : ""],
			s[showLabel ? "showingLabel" : ""],
			s[size],
			className
		),
	};

	let children = (
		<div className={s.svgWrapper}>
			<Icon icon={icon} size={iconSizes[size]} />
		</div>
	);

	if (showLabel) {
		children = (
			<>
				<span>{label}</span>
				<div className={s.svgWrapper}>
					<Icon icon={icon} size={iconSizes[size]} />
				</div>
			</>
		);
	}

	if (as === "a") {
		return createElement("a", props as LinkProps, children);
	}

	return createElement("button", props as ButtonProps, children);
});
