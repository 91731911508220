import { ComponentProps, forwardRef, ForwardedRef } from "react";
import cx from "classnames";

import { IconName } from "@/components/Icon/Icon";

import ButtonContent from "./_ButtonContent";
import s from "./Button.module.scss";

export interface ButtonProps extends ComponentProps<"button"> {
	rank?: "primary" | "secondary" | "tertiary";
	icon?: IconName;
	reverse?: boolean;
	disabled?: boolean;
}

export const Button = forwardRef(function Button(
	{
		className,
		rank = "primary",
		reverse = false,
		icon,
		children,
		disabled = false,
		...rest
	}: ButtonProps,
	ref: ForwardedRef<HTMLButtonElement>
) {
	return (
		<button
			{...rest}
			ref={ref}
			disabled={disabled}
			className={cx(
				s[rank],
				{
					[s.isReversed]: reverse,
					[s.hasIcon]: !!icon,
				},
				className
			)}
		>
			<ButtonContent icon={icon} rank={rank}>
				{children}
			</ButtonContent>
		</button>
	);
});
