import Link, { LinkProps } from "next/link";
import { ComponentPropsWithRef, ForwardedRef, forwardRef } from "react";

import s from "./TextLink.module.scss";

type Props = ComponentPropsWithRef<"a"> & LinkProps;

export const TextLink = forwardRef(function CustomLink(
	{ children, href, ...props }: Props,
	ref: ForwardedRef<HTMLAnchorElement>
) {
	return (
		<Link href={href}>
			<a className={s.link} {...props} ref={ref}>
				{children}
			</a>
		</Link>
	);
});
