import { ComponentProps, createElement, ForwardedRef, forwardRef } from "react";
import cx from "classnames";

import style from "./Text.module.scss";

type SpanProps = ComponentProps<"span"> & {
	ref?: ForwardedRef<HTMLSpanElement>;
};
type ParagraphProps = ComponentProps<"p"> & {
	ref?: ForwardedRef<HTMLParagraphElement>;
};

type Props = (ParagraphProps | SpanProps) & {
	as?: "p" | "span" | "ul" | "ol" | "li";
	size?: "medium" | "small" | "large";
};

export const Text = forwardRef(function Text(
	{ as = "p", children, className, size = "medium", ...rest }: Props,
	ref: ForwardedRef<HTMLParagraphElement | HTMLParagraphElement>
) {
	const props = {
		...rest,
		ref,
		className: cx(style.text, style[size], className),
	};

	return createElement(as, props, children);
});

export const Bold = forwardRef(function Bold(
	{ as = "span", children, className, ...rest }: Props,
	ref: ForwardedRef<HTMLSpanElement>
) {
	const props = {
		...rest,
		ref,
		className: cx(style.bold, className),
	};

	return createElement(as, props, children);
});

export const Italic = forwardRef(function Italic(
	{ as = "span", children, className, ...rest }: Props,
	ref: ForwardedRef<HTMLSpanElement>
) {
	const props = {
		...rest,
		ref,
		className: cx(style.italic, className),
	};

	return createElement(as, props, children);
});

export const Ul = forwardRef(function Ul(
	{ as = "ul", children, className, ...rest }: Props,
	ref: ForwardedRef<HTMLUListElement>
) {
	const props = {
		...rest,
		ref,
		className: cx(style.ul, className),
	};

	return createElement(as, props, children);
});

export const Ol = forwardRef(function Ol(
	{ as = "ol", children, className, ...rest }: Props,
	ref: ForwardedRef<HTMLOListElement>
) {
	const props = {
		...rest,
		ref,
		className: cx(style.ol, className),
	};

	return createElement(as, props, children);
});

export const Li = forwardRef(function Li(
	{ as = "li", children, className, ...rest }: Props,
	ref: ForwardedRef<HTMLLIElement>
) {
	const props = {
		...rest,
		ref,
		className: cx(style.li, className),
	};

	return createElement(as, props, children);
});
